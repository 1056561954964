@import 'suneditor/dist/css/suneditor.min.css';
.related-blogs {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
  li {
    border: 1px solid #ccc;
    border-radius: 30px;
    list-style: none;
    margin: 3px 5px;
    padding: 3px 10px;
    font-size: 90%;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
}